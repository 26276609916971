<template>
  <div class="page-class page-setting-form">
    <h1 class="page-title text-center"
      >{{ title }}
      <b-button
        @click="downloadReport"
        size="sm"
        :variant="isDarkMode ? 'outline-success' : 'success'"
        style="float: right"
        >Download Report</b-button
      >
    </h1>

    <div>
      <div v-if="loading">
        <span class="spinner"></span>
      </div>
      <div v-if="!loading">
        <b-row>
          <b-col cols="12">
            <section>
              <report-collapsible v-if="nps" :rows="npsSubmissions" :columns="columns"></report-collapsible>
            </section>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import ReportCollapsible from '../../components/ReportCollapsible.vue';
import moment from 'moment';

export default {
  name: 'ReportView',
  components: {
    ReportCollapsible
  },
  data() {
    return {
      tag: null,
      uniqueQuestion: null,
      npsSubmissions: [],
      columns: [
        {
          type: 'string',
          headerText: 'Question',
          class: { 'text-center': true },
          textKey: 'question',
          width: '35%'
        },
        {
          type: 'string',
          headerText: 'Rating',
          class: { 'text-center': true },
          textKey: 'rating',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Sub-Question',
          class: { 'text-center': true },
          textKey: 'subQuestion',
          width: '35%'
        },
        {
          type: 'string',
          headerText: 'Response',
          class: { 'text-center': true },
          textKey: 'response',
          width: '15%'
        }
      ]
    };
  },
  async mounted() {
    this.getOne({
      query: {
        id: this.$route.params.id
      }
    });
  },
  computed: {
    title() {
      return 'NPS Report';
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('npsInternal', ['nps', 'loading']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  watch: {
    nps(newValue) {
      if (newValue !== null) {
        const questionGroup = newValue.questionGroup.map(i => i.subQuestionGroup);

        for (let i = 0; i < questionGroup.length; i++) {
          for (let j = 0; j < questionGroup[i].length; j++) {
            questionGroup[i][j].question = newValue.questionGroup[i].question;
            questionGroup[i][j].rating = newValue.questionGroup[i].rating;

            if (questionGroup[i][j].response === '0') {
              questionGroup[i][j].response = null;
            }
          }
        }
        this.npsSubmissions = _.flattenDeep(questionGroup);

        for (let i = 0; i < newValue.questionGroup.length; i++) {
          this.npsSubmissions.push({
            question: newValue.questionGroup[i].question,
            rating: newValue.questionGroup[i].rating,
            subQuestion: '-',
            response: '-'
          });
        }
      } else {
        this.npsSubmissions = [];
      }
    }
  },
  methods: {
    ...mapActions('npsInternal', ['getOne']),
    downloadReport() {
      let csv = '';
      csv += `,Submission Date - ${moment(this.nps.updatedAt).format('DD-MM-YYYY')}\n`;
      csv += `,\n`;
      csv += `Question,Rating,Sub-Question,Response\n`;
      this.npsSubmissions.forEach(i => {
        csv += `${i.question},${i.rating},${i.subQuestion},${
          i.response === null ? 'NA' : i.response === 0 ? 'NA' : i.response
        }\n`;
      });
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'NPS Submission Report.csv';
      anchor.click();
    }
  }
};
</script>

<style scoped>
.scrollbar-list {
  padding: 0 24px;
  font-size: 0.95rem;
  color: white;
  transition: background 0.2s, padding 0.2s;
  background-color: #4e5359;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}
.is-active {
  background: #42464b !important;
}
.header {
  margin-top: 2rem;
}
</style>
