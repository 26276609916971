<template>
  <div class="page-class page-setting-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <feedback-list-box
      :columns="columns"
      :rows="nps"
      :baseUrl="baseUrl"
      :loading="loading"
      :pagination="pagination"
      emptyText="No Data found."
      downloadText="Download List"
      :downloadReportText="'Download Report'"
      :showAdd="true"
      addText="Download Question-wise Report"
      :showReportDownload="true"
      :showPagination="true"
      :showToolbar="true"
      :showDownload="true"
      :steps="steps"
      :callbacks="callbacks"
      :searchItems="searchItems"
      @open="onView"
      @download="onExportCSV"
      @add="question"
      @downloadreport="onDownloadReport"
    >
    </feedback-list-box>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import FeedbackListBox from '@/components/FeedbackListBox.vue';
import router from '@/router';
import _ from 'lodash';

export default {
  name: 'NPSList',
  components: {
    FeedbackListBox
  },
  metaInfo() {
    return {
      title: 'NPS-Internal',
      meta: [
        {
          name: 'description',
          content: 'View NPS-Internal'
        }
      ]
    };
  },
  mounted() {
    this.listNPS({ query: this.$route.query });
  },
  beforeRouteUpdate(to, from, next) {
    this.listNPS({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Voice Of Customer',
      callbacks: {
        onFinish() {
          window.localStorage.setItem('hasCompletedReport', 'true');
        }
      },
      steps: [
        {
          target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
          content: `Enter search criteria based on Checklist Name or Store Id to Filter the List of Reports.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="1"]',
          content: 'Select Filter to further refine the search Results.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="2"]',
          content: 'Select Date Range to further refine search results and click Apply button.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="6"]',
          content: 'View the Report details in new page.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'index',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'Mobile',
          textKey: 'mobile',
          class: { 'text-center': true },
          width: '11%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          textKey: 'storeid',
          class: { 'text-center': true },
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Bill Type',
          textKey: 'billType',
          class: { 'text-center': true },
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Bill Number',
          textKey: 'billNumber',
          class: { 'text-center': true },
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Bill Amount',
          textKey: 'billAmount',
          class: { 'text-center': true },
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Bill Date',
          textKey: 'billDate',
          class: { 'text-center': true },
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Rating Date',
          class: { 'text-center': true },
          textKey: 'ratingDate',
          width: '12%'
        },

        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '12%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '9%',
          functions: {
            open: true,
            edit: false
          }
        }
      ],
      searchItems: ['Mobile', 'Store Id']
    };
  },
  computed: {
    ...mapState('npsInternal', [
      'loading',
      'baseUrl',
      'npsList',
      'pagination',
      'downloadNPSList',
      'downloadNPSReport',
      'downloadQuestionWise'
    ]),
    nps() {
      return this.npsList.map(i => ({
        ...i
      }));
    }
  },
  methods: {
    ...mapActions('npsInternal', ['listNPS', 'download', 'downloadQuestionWiseReport', 'downloadReportNPS']),

    onView({ row }) {
      router.push(`/nps/${row.id}`);
    },
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 99999,
            page: 1,
            startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      }
    },
    question() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.downloadQuestionWiseReport({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.downloadQuestionWiseReport({
          query: {
            ...this.$route.query,
            page_size: 99999,
            page: 1,
            startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      }
    },
    onDownloadReport({}) {
      this.downloadReportNPS({ query: { ...this.$route.query }, router });
    }
  },
  watch: {
    downloadQuestionWise(newValue) {
      if (newValue.length > 0) {
        const data = newValue.map(i => i.questionGroup);
        const question = data.map(i => i.map(j => j.question));
        const rating = data.map(i => i.map(j => j.rating));
        const data1 = data.map(i => i.map(j => j.subQuestionGroup));
        const subQuestion = data1.map(i => i.map(j => j.map(k => k.subQuestion)));
        const response = data1.map(i =>
          i.map(j => j.map(k => (k.response === null ? '-' : k.response === undefined ? '-' : k.response)))
        );
        const mobile = newValue.map(i => i.mobile);
        const storeid = newValue.map(i => i.storeid);
        const billNumber = newValue.map(i => i.billNumber);
        const billAmount = newValue.map(i => i.billAmount);
        const billDate = newValue.map(i => i.billDate);
        const billType = newValue.map(i => i.billType);
        const createdAt = newValue.map(i => i.createdAt);
        const updatedAt = newValue.map(i => i.updatedAt);
        const ratingDate = newValue.map(i => i.ratingDate);
        const submittedBy = newValue.map(i =>
          i.submittedByName === null
            ? '-'
            : i.submittedByName === undefined
            ? '-'
            : i.submittedByName === ''
            ? '-'
            : i.submittedByName
        );
        const isSubmitted = newValue.map(i => i.isSubmitted);
        const status = newValue.map(i =>
          i.actionStatus === null
            ? '-'
            : i.actionStatus === undefined
            ? '-'
            : i.actionStatus === ''
            ? '-'
            : i.actionStatus
        );
        const storeActionRemarks = newValue.map(i =>
          i.storeActionRemarks === null
            ? '-'
            : i.storeActionRemarks === undefined
            ? '-'
            : i.storeActionRemarks === ''
            ? '-'
            : i.storeActionRemarks
        );
        const storeActionRemarks1 = storeActionRemarks.join().split(',');

        const questionWithSubQuestion = question.map((i, index) =>
          i.map((j, index1) => ({
            question: j,
            subQuestion: subQuestion[index][index1],
            response: response[index][index1],
            rating: rating[index][index1],
            mobile: mobile[index],
            storeid: storeid[index],
            billNumber: billNumber[index],
            billType: billType[index],
            billAmount: billAmount[index],
            billDate: billDate[index],
            createdAt: createdAt[index],
            updatedAt: updatedAt[index],
            ratingDate: ratingDate[index],
            submittedBy: submittedBy[index],
            isSubmitted: isSubmitted[index],
            status: status[index],
            storeActionRemarks: storeActionRemarks1[index]
          }))
        );

        let csv = '';
        csv += `,,Report From   ${
          this.$route.query.startdate === undefined
            ? moment().subtract(1, 'month').format('DD-MM-YYYY')
            : moment(this.$route.query.startdate).format('DD-MM-YYYY')
        }    to    ${
          this.$route.query.enddate === undefined
            ? moment().format('DD-MM-YYYY')
            : moment(this.$route.query.enddate).format('DD-MM-YYYY')
        }`;
        csv += `,,\n`;
        csv += `,,\n`;
        csv +=
          'Store Id,Mobile,Bill Number,Bill Type,Bill Amount,Bill Date,Rating Date,Submitted,Status,Store Action Remarks,Submitted By,Question,Rating,Sub Question,Response,CreatedAt,UpdatedAt\n';
        questionWithSubQuestion.forEach(i => {
          i.forEach(j => {
            if (j.subQuestion.length > 0) {
              j.subQuestion.forEach((k, index) => {
                csv += `${j.storeid},${j.mobile},${j.billNumber},${j.billType},${j.billAmount},${j.billDate},${
                  j.ratingDate
                },${j.isSubmitted},${j.status},${j.storeActionRemarks},${j.submittedBy},${j.question},${
                  j.rating
                },${k},${j.response[index].replaceAll(',', '').replaceAll('\n', '')},${j.createdAt},${j.updatedAt}\n`;
              });
            } else {
              csv += `${j.storeid},${j.mobile},${j.billNumber},${j.billType},${j.billAmount},${j.billDate},${j.ratingDate},${j.isSubmitted},${j.status},${j.storeActionRemarks},${j.submittedBy},${j.question},${j.rating},-,-,${j.createdAt},${j.updatedAt}\n`;
            }
          });
        });

        // questionWithSubQuestion.forEach(row => {
        //   row.forEach(i => {
        //     i.subQuestion.forEach((j, index) => {
        //       csv += `${i.storeid},${i.mobile},${i.billNumber},${i.billType},${i.billAmount},${i.billDate},${i.ratingDate},${i.isSubmitted},${i.status},"${i.storeActionRemarks}","${i.submittedBy}","${i.question}",${i.rating},"${j}","${i.response[index]}",${i.createdAt},${i.updatedAt}\n`;
        //     });
        //   });
        // });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'NPS Submission Report.csv';
        anchor.click();
      }
    },
    downloadNPSList(newValue) {
      if (newValue.length) {
        const { startdate, enddate } = this.$route.query;
        let csv = '';
        if (startdate && enddate) {
          csv += `,Date From ${moment(startdate).format('DD-MM-YYYY')} to ${moment(enddate).format('DD-MM-YYYY')},\n`;
        } else {
          csv += `,Date From ${moment().subtract(1, 'month').format('DD-MM-YYYY')} to ${moment().format(
            'DD-MM-YYYY'
          )},\n`;
        }
        csv += 'Row Number,Mobile,Store Id,Bill Type,Bill Number,Bill Amount,Bill Date,Rating Date,Created At\n';
        newValue.forEach(row => {
          csv += `"${row.index}","${row.mobile}","${row.storeid}","${row.billType}","${row.billNumber}","${row.billAmount}","${row.billDate}","${row.ratingDate}","${row.createdAt}"\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'NPS List.csv';
        anchor.click();
      }
    },
    downloadNPSReport(newValue) {
      if (newValue.length) {
        const { startdate, enddate } = this.$route.query;
        let csv = '';
        if (startdate && enddate) {
          csv += `,Date From ${moment(startdate).format('DD-MM-YYYY')} to ${moment(enddate).format('DD-MM-YYYY')},\n`;
        } else {
          csv += `,Date From ${moment().format('DD-MM-YYYY')} to ${moment().format('DD-MM-YYYY')},\n`;
        }
        csv += ',,,\n';
        csv +=
          'Mobile,Store Id,Bill Type,Bill Number,Bill Amount,Bill Date,Rating Date,Created At,Overall Rating,Action Status,Resolution Status,Store Remarks, Updated At\n';
        newValue.forEach(row => {
          const getRatingFRomLastElement = row.OverallRating.length
            ? row.OverallRating[row.OverallRating.length - 1].rating
            : '';
          csv += `"${row.mobile}","${row.storeid}","${row.billType}","${row.billNumber}","${row.billAmount}","${moment(
            row.billDate
          ).format('DD-MM-YYYY')}","${moment(row.ratingDate).format('DD-MM-YYYY')}","${moment(row.createdAt).format(
            'DD-MM-YYYY  hh:mm:  A'
          )}","${getRatingFRomLastElement}","${
            row.isDraft === true ? 'In Progress' : row.isSubmitted === true ? 'Resolved' : 'Not Attempted'
          }","${row.resolutionStatus === null ? '-' : row.resolutionStatus}","${
            row.storeRemarks === null ? '-' : row.storeRemarks
          }","${moment(row.updatedAt).format('DD-MM-YYYY  hh:mm:  A')}"\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'NPS Report.csv';
        anchor.click();
      }
    }
  }
};
</script>
