<template>
  <div class="page-class page-setting-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <table-box
      :columns="columns"
      :rows="storeList"
      :baseUrl="baseUrl"
      :loading="loading"
      emptyText="No store found."
      :showAdd="false"
      @edit="onEdit"
    >
    </table-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TableBox from '@/components/TableBox.vue';
import router from '@/router';

export default {
  name: 'SettingList',
  components: {
    TableBox
  },
  metaInfo() {
    return {
      title: 'Settings',
      meta: [
        {
          name: 'description',
          content: 'Manage settings'
        }
      ]
    };
  },
  mounted() {
    this.listStore({ query: this.$route.query });
  },
  data() {
    return {
      title: 'Settings',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'rowNum',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          textKey: 'storeId',
          width: '72%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '20%',
          functions: {
            edit: true
          }
        }
      ]
    };
  },
  computed: {
    ...mapState('setting', ['loading', 'baseUrl', 'storeList'])
  },
  methods: {
    ...mapActions('setting', ['listStore', 'deleteOne']),
    onEdit({ row }) {
      router.push(`/setting/${row.storeId}`);
    }
  }
};
</script>
