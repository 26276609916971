<template>
  <div class="page-class page-setting-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <table-box
      :columns="columns"
      :rows="report"
      :baseUrl="baseUrl"
      :loading="loading"
      :pagination="pagination"
      :showFilter="true"
      emptyText="No Report found."
      downloadText="Download Report"
      downloadMisText="Download MIS"
      :showAdd="false"
      :showPagination="true"
      :showToolbar="true"
      :showDownload="true"
      :showDownloadMis="true"
      :filterOptions="filterOptions"
      :steps="steps"
      :callbacks="callbacks"
      :searchItems="searchItems"
      @open="onView"
      @download="onExportCSV"
      @download-audit="onAudit"
      @download-mis="onDownloadMis"
    >
    </table-box>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions, mapGetters } from 'vuex';
import TableBox from '@/components/TableBox.vue';
import router from '@/router';
import _ from 'lodash';

export default {
  name: 'ReportList',
  components: {
    TableBox
  },
  metaInfo() {
    return {
      title: 'Reports',
      meta: [
        {
          name: 'description',
          content: 'View Reports'
        }
      ]
    };
  },
  mounted() {
    // if (window.localStorage.getItem('hasCompletedReport') !== 'true') {
    //   this.$tours.myTour.start();
    // }
    // if (this.isFoodSafetyTeam) {
    //   router.push('/foodSafety_Reports');
    // } else
    if (this.ifPettyCashTeam) {
      router.push('/petty-cash');
    } else {
      this.listStore({ router });
      if (_.isEmpty(this.$route.query)) {
        this.listReport({
          query: {
            ...this.$route.query,
            startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      } else {
        this.listReport({ query: this.$route.query });
      }
    }
  },

  beforeRouteUpdate(to, from, next) {
    this.listReport({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Reports',
      stores: [{ text: 'All Stores', value: 'all' }],
      storeSelected: 'all',
      callbacks: {
        onFinish() {
          window.localStorage.setItem('hasCompletedReport', 'true');
        }
      },
      steps: [
        {
          target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
          content: `Enter search criteria based on Checklist Name or Store Id to Filter the List of Reports.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="1"]',
          content: 'Select Filter to further refine the search Results.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="2"]',
          content: 'Select Date Range to further refine search results and click Apply button.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="6"]',
          content: 'View the Report details in new page.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'rowNum',
          width: '3%'
        },
        {
          type: 'string',
          headerText: 'CheckList Name',
          textKey: 'checkListName',
          class: { 'text-center': true },
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Submitted By',
          textKey: 'submittedBy',
          class: { 'text-center': true },
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Completed',
          textKey: 'completed',
          class: { 'text-center': true },
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Store Name',
          textKey: 'storeName',
          class: { 'text-center': true },
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Location',
          urlKey: 'location',
          class: { 'text-center': true },
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          textKey: 'storeid',
          class: { 'text-center': true },
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Cluster',
          class: { 'text-center': true },
          textKey: 'cluster',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Region',
          class: { 'text-center': true },
          textKey: 'region',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Format',
          class: { 'text-center': true },
          textKey: 'format',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'updatedAt',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Overall Score',
          class: { 'text-center': true },
          textKey: 'overallScore',
          width: '8%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '11%',
          functions: {
            open: true,
            audit: true
          }
        }
      ],
      searchItems: ['CheckList Name', 'Store Id'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Completed-True', text: 'Completed-True' },
        { value: 'Completed-False', text: 'Completed-False' }
      ]
    };
  },
  computed: {
    ...mapState('report', ['loading', 'baseUrl', 'reportList', 'pagination', 'downloadReportList']),
    ...mapState('mis', ['auditReport', 'misList', 'misCountList']),
    ...mapState('store', ['storeList']),
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isFoodSafetyTeam']),
    ...mapGetters('pettyCash', ['ifPettyCashTeam']),
    report() {
      return this.reportList.map(i => ({
        ...i,
        completed: `${i.completed} - ${i.overallProgress === -1 ? 'NA' : (i.overallProgress * 100).toFixed(0)} ${'%'}`
      }));
    }
  },
  methods: {
    ...mapActions('report', ['listReport', 'download']),
    ...mapActions('store', ['listStore', 'listCluster', 'listRegion', 'listByUser']),
    ...mapActions('mis', ['downloadMis', 'downloadAuditReport']),
    onAudit({ row }) {
      if (_.isEmpty(this.$route.query)) {
        this.downloadAuditReport({
          query: {
            reportId: row.id
          }
        });
      } else {
        this.downloadAuditReport({
          query: {
            reportId: row.id,
            ...this.$route.query
          }
        });
      }
    },
    calculateRegionWise(data) {
      const counts = data.reduce((p, c) => {
        const mis = p;
        if (!Object.prototype.hasOwnProperty.call(mis, c.region)) {
          mis[c.region] = { ...c };
        }
        Object.keys(c).forEach(key => {
          if (Object.prototype.hasOwnProperty.call(c, key) && !['storeid', 'region', 'cluster', 'row'].includes(key)) {
            mis[c.region][key] += c[key];
          }
        });
        return mis;
      }, {});
      return Object.values(counts);
    },
    calculateClusterWise(data) {
      const counts = data.reduce((p, c) => {
        const mis = p;
        if (!Object.prototype.hasOwnProperty.call(mis, c.cluster)) {
          mis[c.cluster] = { ...c };
        }
        Object.keys(c).forEach(key => {
          if (Object.prototype.hasOwnProperty.call(c, key) && !['storeid', 'region', 'cluster'].includes(key)) {
            mis[c.cluster][key] += c[key];
          }
        });
        return mis;
      }, {});
      return Object.values(counts);
    },
    onView({ row }) {
      router.push(`/report/${row.id}`);
    },
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 99999,
            page: 1,
            startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      }
    },
    onDownloadMis({ download = true }) {
      let storesList = [];
      if (this.storeSelected === 'all') {
        storesList = this.stores.map(s => s.value);
        storesList.shift();
      } else {
        storesList = this.storeSelected;
      }
      this.downloadMis({
        query: {
          startdate: moment(this.$route.query.startdate).format('YYYY-MM-DD'),
          enddate: moment(this.$route.query.enddate).format('YYYY-MM-DD'),
          stores: storesList
        },
        download
      });
    }
    // onDownload() {
    //   let csv = 'Row Number,Checklist Name,Submitted By,Completed,Store Name,Store Id,Overall Score\n';
    //   this.report.forEach(row => {
    //     Object.values(row).forEach((val, index) => {
    //       if ([1, 2, 3, 4, 5, 6, 7].includes(index)) {
    //         csv += `"${val}"`;
    //         csv += ',';
    //       }
    //     });
    //     csv += '\n';
    //   });
    //   const anchor = document.createElement('a');
    //   anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    //   anchor.target = '_blank';
    //   anchor.download = 'SubmittedByMIS.csv';
    //   anchor.click();
    // }
  },
  watch: {
    auditReport(newValue) {
      if (newValue) {
        const arr = newValue.map(i => ({
          subcategory: i._id,
          storeid: i.storeid,
          storeName: i.storeName,
          storeManagerName: i.storeManagerName,
          submittedOn: i.submittedOn ? moment(i.submittedOn).format('YYYY-MM-DD hh:mm:ss') : '',
          submittedByName: i.submittedByName,
          targetWeightage: i.totalWeightage,
          achivedWeightage: i.achivedWeightage,
          achivementPercentage: i.achivementPercentage.toFixed(2) + '%'
        }));
        let csv = '';
        let arr2 = [];
        arr2.push(arr[0]);
        csv += `Store Id, Store Name , Store Manager Name, Audit Date, Auditor Name\n`;
        arr2.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          csv += ',\n';
        });
        csv += ',\n';
        csv += ',\n';
        csv += 'RowNumber , Particulars, Total Weightage,  Achieved Weightage,  Achieved Percentage\n';
        arr.forEach((row, index) => {
          csv += `${index + 1},${row.subcategory},${row.targetWeightage},${row.achivedWeightage},${
            row.achivementPercentage
          }\n`;
        });
        const totalOfTarget = arr.reduce((acc, cur) => acc + cur.targetWeightage, 0);
        const totalOfAchived = arr.reduce((acc, cur) => acc + cur.achivedWeightage, 0);
        const totalPercentage = (totalOfAchived / totalOfTarget) * 100;
        csv += `,Total,${totalOfTarget},${totalOfAchived},${totalPercentage}%\n`;

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'AuditReport.csv';
        anchor.click();
      }
    },
    misList(newValue) {
      if (newValue.length) {
        let csv = `,,,,,MIS from ${moment(this.$route.query.startdate).format('DD-MM-YYYY')} to ${moment(
          this.$route.query.enddate
        ).format('DD-MM-YYYY')}\n`;
        let processedValue = newValue;
        switch (this.chartSelected) {
          case 'region': {
            csv += 'Region,';
            processedValue = this.calculateRegionWise(newValue);
            processedValue = processedValue.map(item => {
              const region = item;
              delete region.storeid;
              delete region.cluster;
              delete region.row;
              return region;
            });
            break;
          }

          case 'cluster': {
            csv += 'Region,Cluster,';
            processedValue = this.calculateClusterWise(newValue);
            processedValue = processedValue.map(item => {
              const region = item;
              delete region.storeid;
              delete region.row;
              return region;
            });
            break;
          }

          default:
            csv += 'Row Number,Store Id,Region,Cluster,';
            break;
        }
        csv +=
          'Calls Landed Total,' +
          'Calls Answered,Calls Not Answered,' +
          'Calls Busy,Calls Failed,Calls Feedback(Not Selected),' +
          'Calls Feedback(Serviced),Calls Feedback(Not Available),' +
          'Calls Feedback(Enquiry Call),Calls Feedback(Out of Service Area),' +
          'Calls Feedback(Not Reachable),Single Order,' +
          'Bulk Order,Outbound Order,IVR Order,Acknowledged,Delivered,Call Recordings,' +
          'Recordings Feedback(Not Selected),Recordings Feedback(Serviced),' +
          'Recordings Feedback(Not Available),Recordings Feedback(Enquiry Call),' +
          'Recordings Feedback(Out of Service Area),Recordings Feedback(Not Reachable),' +
          'Outbound(Call Dialed), Outbound(Call Connected),Outbound(Terminated By Staff),Outbound(Ringed),Outbound(User Not Available' +
          '\n';
        const sumRow = [];
        processedValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            csv += `"${val}"`;
            csv += ',';
            if (typeof val !== 'number' || index === 0) {
              sumRow[index] = '';
              return;
            }

            if (sumRow[index]) {
              sumRow[index] += val;
            } else {
              sumRow[index] = val;
            }
          });
          csv += '\n';
        });
        sumRow[0] = 'India Total';
        csv += `${sumRow.join(',')}\n`;

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'MIS.csv';
        anchor.click();
      }
    },
    misCountList(newValue) {
      if (newValue.length) {
        const keys = newValue[0].count.map(c => Object.keys(c)[0]).join(',');
        let csv = `,,MIS from ${moment(this.$route.query.startdate).format('DD-MM-YYYY')} to ${moment(
          this.$route.query.enddate
        ).format('DD-MM-YYYY')}\n`;
        csv += `Store Id,Store Name,${keys}\n`;
        Object.values(newValue).forEach(val => {
          csv += `${val.storeid},${val.storeName.replaceAll(',', '_')},`;
          Object.values(val.count).forEach(countVal => {
            csv += `${Object.values(countVal)[0]},`;
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'MIS.csv';
        anchor.click();
      }
    },
    storeList(newValue) {
      if (this.user.roles.filter(role => role.name === 'ADMIN')) {
        this.stores = [{ text: 'All Stores', value: 'all' }];
        this.stores = [...this.stores, ...newValue.map(store => ({ text: store.storeid, value: store.storeid }))];
      } else {
        this.stores = [{ text: 'All Stores', value: 'all' }];
        this.stores = [...this.stores, ...this.user.storeids.map(store => ({ text: store, value: store }))];
      }
    },
    downloadReportList(newValue) {
      if (newValue.length) {
        const { startdate, enddate } = this.$route.query;
        let csv = '';
        if (startdate && enddate) {
          csv += `,Date From ${startdate} to ${enddate},\n`;
        } else {
          csv += `,Date From ${moment().format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')},\n`;
        }
        csv +=
          'Row Number,Checklist Name,Submitted By,Completed,Store Name,Store Id,Cluster,Region,Format,Overall Score,Created At,Location\n';
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'SubmittedByMIS.csv';
        anchor.click();
      }
    }
  }
};
</script>
