<template>
  <div class="page-class page-setting-form">
    <h1 class="page-title text-center">{{ title }}</h1>
    <div>
      <div v-if="loading || !formLoaded">
        <span class="spinner"></span>
      </div>
      <div v-if="!loading && formLoaded">
        <b-form @submit.stop.prevent="onSubmit">
          <b-card bg-variant="light">
            <b-form-group
              label-cols-lg="8"
              label="Name"
              label-size="lg"
              label-class="font-weight-bold pt-0"
              class="mb-2"
            >
            </b-form-group>
            <b-form-group>
              <b-form-input v-model="name" type="text" placeholder=" Enter Name"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols-lg="8"
              label="Employee Id"
              label-size="lg"
              label-class="font-weight-bold pt-0"
              class="mb-2"
            >
            </b-form-group>
            <b-form-group>
              <b-form-input v-model="empid" type="text" placeholder=" Enter Employee Id"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols-lg="8"
              label="Desgination"
              label-size="lg"
              label-class="font-weight-bold pt-0"
              class="mb-2"
            >
            </b-form-group>
            <b-form-group>
              <b-form-input v-model="designation" type="text" placeholder=" Enter Designation"></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols-lg="8"
              label="SMS Number"
              label-size="lg"
              label-class="font-weight-bold pt-0"
              class="mb-2"
            >
            </b-form-group>
            <b-form-group>
              <b-form-input v-model="smsNumber" type="text" placeholder=" Enter SMS Number"></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols-lg="2"
              label="Manager Numbers"
              label-size="lg"
              label-class="font-weight-bold pt-0"
              class="mb-2"
            >
            </b-form-group>
            <b-form-group>
              <div
                v-for="(input, index) in managerNumbers"
                :key="`manager-${index}`"
                class="input wrapper flex items-center"
              >
                <b-row class="mb-2">
                  <b-col sm="10">
                    <b-row>
                      <b-col>
                        <b-form-input
                          :value="managerNumbers[index].number"
                          @change="handleChange($event, index, managerNumbers, 'number')"
                          type="text"
                          placeholder=" Enter Number"
                        ></b-form-input>
                      </b-col>
                      <b-col>
                        <b-form-input
                          :value="managerNumbers[index].name"
                          @change="handleChange($event, index, managerNumbers, 'name')"
                          type="text"
                          placeholder=" Enter Name"
                        ></b-form-input>
                      </b-col>
                      <b-col>
                        <b-form-input
                          :value="managerNumbers[index].empid"
                          @change="handleChange($event, index, managerNumbers, 'empid')"
                          type="text"
                          placeholder=" Enter Employee Id"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col sm="2">
                    <b-button @click="removeField(index, managerNumbers)" variant="danger">Delete</b-button>
                  </b-col>
                </b-row>
              </div>
              <b-button @click="addField(managerNumbers)" variant="primary">Add More</b-button>
            </b-form-group>
            <b-form-group
              label-cols-lg="8"
              label="Pincodes"
              label-size="lg"
              label-class="font-weight-bold pt-0"
              class="mb-2"
            >
            </b-form-group>
            <b-form-group>
              <div v-for="(input, index) in pincodes" :key="`pincode-${index}`" class="input wrapper flex items-center">
                <b-row class="mb-2">
                  <b-col cols="4">
                    <b-form-input
                      :value="pincodes[index]"
                      type="text"
                      placeholder=" Enter Pincode"
                      @change="handleChange($event, index, pincodes, null)"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="1">
                    <b-button @click="removeField(index, pincodes)" variant="danger">Delete</b-button>
                  </b-col>
                </b-row>
              </div>
              <b-button @click="addField(pincodes)" variant="primary">Add More</b-button>
            </b-form-group>
          </b-card>

          <template v-if="error">
            <b-row class="mb-2">
              <b-col class="text-danger message-col">{{ error }}</b-col>
            </b-row>
          </template>

          <b-row class="mt-2">
            <b-col class="text-right">
              <b-button type="submit" size="sm" variant="success" :disabled="loading">
                <span class="spinner spinner-white" v-if="loading"></span>
                <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
              </b-button>
              <b-button class="ml-2" size="sm" variant="warning" :to="{ path: `/setting` }">
                <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import router from '@/router';
import _ from 'lodash';

export default {
  name: 'SettingForm',
  components: {},
  data() {
    return {
      storeId: null,
      formLoaded: false,
      pincodes: [''],
      managerNumbers: [''],
      smsNumber: null,
      name: null,
      designation: null,
      empid: null,
      error: null
    };
  },
  async mounted() {
    this.storeId = this.$route.params.id;
    this.getStoreData({
      storeId: this.storeId,
      router
    });
    this.getOne({
      type: 'staff',
      mobile: this.authUser.mobile,
      router
    });
  },
  computed: {
    title() {
      return 'Update setting';
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('setting', ['loading', 'storeData']),
    ...mapState('user', ['user']),
    ...mapState('auth', {
      authUser: 'user'
    })
  },
  methods: {
    ...mapActions('setting', ['getStoreData', 'patchStore']),
    ...mapActions('user', ['getOne', 'patchOne']),
    handleChange(value, index, fieldType, fieldName) {
      if (fieldName) {
        fieldType.splice(index, 1, { ...this.managerNumbers[index], [fieldName]: value });
      } else {
        fieldType.splice(index, 1, value);
      }
    },
    addField(fieldType) {
      fieldType.push('');
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    async onSubmit() {
      this.error = null;
      if (!this.managerNumbers.length) {
        this.error = 'Error, Please provide atleast one Manager Number';
        return false;
      }
      if (!this.pincodes.length) {
        this.error = 'Error, Please provide atleast one Pincode';
        return false;
      }
      this.managerNumbers.forEach((manager, index) => {
        if (manager.number.length < 9 && manager.number.length > 13) {
          this.error = `Error, manager number is not valid at position ${index + 1}`;
          return false;
        }
        if (!manager.name) {
          this.error = `Error, manager name is not valid at position ${index + 1}`;
          return false;
        }
        if (!manager.empid) {
          this.error = `Error, employee Id is not valid at position ${index + 1}`;
          return false;
        }
        return true;
      });

      this.pincodes.forEach((pincode, index) => {
        if (pincode.length !== 6) {
          this.error = `Error, pincode is not valid at position ${index + 1}`;
          return false;
        }
        return true;
      });

      if (!this.smsNumber || this.smsNumber.length !== 10) {
        this.error = 'Sms Number is not valid';
        return false;
      }

      if (this.error) {
        return false;
      }
      await this.patchOne({
        type: 'staff',
        mobile: this.authUser.mobile,
        user: {
          ...this.user,
          name: this.name,
          empid: this.empid,
          designation: this.designation
        },
        router
      });
      this.patchStore({
        storeId: this.storeId,
        pincodes: this.pincodes,
        managerNumbers: this.managerNumbers,
        smsNumber: this.smsNumber,
        updateManagerNumber: !_.isEqual(this.storeData.managerNumbers, this.managerNumbers),
        router,
        redirectUrl: '/setting'
      });
      return false;
    },
    onAdd({ setting }) {
      this.postOne({
        setting,
        router,
        redirectUrl: '/setting'
      });
    },
    async onEdit({ setting }) {
      this.patchOne({
        settingId: this.settingId,
        setting,
        router,
        redirectUrl: '/setting'
      });
    }
  },
  watch: {
    user(_newValue) {
      this.name = _newValue.name;
      this.designation = _newValue.designation;
      this.empid = _newValue.empid;
    },
    storeData(_newValue, _oldValue) {
      if (!this.storeData) {
        return;
      }
      this.pincodes = JSON.parse(JSON.stringify(this.storeData.pincodes));
      this.managerNumbers = JSON.parse(JSON.stringify(this.storeData.managerNumbers));
      this.smsNumber = JSON.parse(JSON.stringify(this.storeData.smsNumber));
      this.formLoaded = true;
    }
  }
};
</script>
