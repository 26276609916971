<template>
  <div class="page-class page-setting-form">
    <h1 class="page-title text-center"
      >{{ title }}
      <b-button
        @click="downloadReport"
        size="sm"
        :variant="isDarkMode ? 'outline-success' : 'success'"
        style="float: right"
        >Download Report</b-button
      ></h1
    >
    <div>
      <div v-if="loading">
        <span class="spinner"></span>
      </div>
      <div v-if="!loading">
        <b-row>
          <!-- <b-col cols="3">
            <affix
              class="sidebar-menu"
              relative-element-selector="#example-content"
              style="width: 300px"
              :offset="{ top: 100, bottom: 40 }"
            >
              <scrollactive class="my-nav" v-if="report" :offset="80" :duration="800">
                <ul style="list-style-type: none">
                  <li
                    :key="index"
                    v-for="(item, index) in uniqueCheckpoint"
                    :data-section-selector="`#section${item.replace(' ', '-')}`"
                    class="scrollactive-item scrollbar-list"
                    >{{ item }}</li
                  >
                </ul>
              </scrollactive>
            </affix>
          </b-col> -->
          <b-col cols="12">
            <section>
              <report-collapsible
                v-if="report"
                :rows="reportSubmissions"
                :columns="columns"
                @comment="onComment"
              ></report-collapsible>
            </section>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="header">
      <h4>Comments</h4>
    </div>
    <comment @remove="onRemove2" :tag="tag" :reportId="$route.params.id"> </comment>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
// import Scrollactive from 'vue-scrollactive/src/scrollactive.vue';
// import { Affix } from 'vue-affix';
import _ from 'lodash';
import ReportCollapsible from '../../components/ReportCollapsible.vue';
import Comment from '../Comment/Comment.vue';
import moment from 'moment';

export default {
  name: 'ReportView',
  components: {
    ReportCollapsible,
    Comment
    // Scrollactive,
    // Affix
  },
  data() {
    return {
      tag: null,
      uniqueCheckpoint: null,
      columns: [
        {
          type: 'string',
          headerText: 'Category',
          class: { 'text-center': true },
          textKey: 'category',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Sub Category',
          class: { 'text-center': true },
          textKey: 'subCategory',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Question',
          class: { 'text-center': true },
          textKey: 'questionText',
          width: '30%'
        },
        {
          type: 'string',
          headerText: 'Q-Type',
          class: { 'text-center': true },
          textKey: 'checkpointType',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Target',
          class: { 'text-center': true },
          textKey: 'target',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Actual',
          class: { 'text-center': true },
          textKey: 'actual',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Score',
          class: { 'text-center': true },
          textKey: 'score',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Remarks',
          class: { 'text-center': true },
          textKey: 'remarks',
          width: '6%'
        },
        {
          type: 'image',
          headerText: 'Image',
          class: { 'text-center': true },
          textKey: 'picture',
          width: '13%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '13%',
          functions: {
            comment: true
          }
        }
      ]
    };
  },
  async mounted() {
    this.getOne({
      query: {
        id: this.$route.params.id
      }
    });
  },
  computed: {
    title() {
      return 'Report';
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('report', ['report', 'loading']),
    ...mapState('darkMode', ['isDarkMode']),
    reportSubmissions() {
      return this.report.submissions.map((i, index) => ({
        ...i,
        rowNum: index + 1,
        questionText: i.questionText ? i.questionText : '',
        category: i.category ? i.category : '',
        subCategory: i.subCategory ? i.subCategory : '',
        score: i.score === -1 ? 'NA' : i.score,
        checkpointType: i.checkpointType ? i.checkpointType : '',
        picture: i.picture ? i.picture : []
      }));
    }
  },
  watch: {
    report(newValue) {
      if (newValue !== null) {
        this.uniqueCheckpoint = _.uniqBy(_.flatMap(newValue.submissions, 'checkpoint'), 'category').map(
          i => i.category
        );
      }
    }
  },
  methods: {
    ...mapActions('report', ['getOne']),
    onRemove2() {
      this.tag = null;
    },
    onComment(value) {
      this.tag = value.row.checkpoint;
      document.getElementById('myText').focus();
    },
    downloadReport() {
      let csv = '';
      csv += `,Submission Date - ${moment(this.reportSubmissions.updatedAt).format('DD-MM-YYYY')}\n`;
      csv += `,\n`;
      csv += 'Row Number,Category,Sub Category,Question,Q-Type,Target,Actual,Score,Remarks,Image\n';

      this.reportSubmissions.forEach(i => {
        const temp = `${i.rowNum},${i.category},${i.subCategory},${i.questionText.replace(/,/g, '')},${
          i.checkpointType
        },${i.target === null ? 'NA' : i.target},${i.actual === null ? 'NA' : i.actual},${i.score},${i.remarks},`;
        if (i.picture.length <= 0) {
          csv += temp;
          csv += `NA\n`;
        } else {
          i.picture.forEach(pic => {
            csv += temp;
            csv += `${pic}\n`;
          });
        }
        csv += `\n`;
      });
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'Submission Report.csv';
      anchor.click();
    }
  }
};
</script>

<style scoped>
.scrollbar-list {
  padding: 0 24px;
  font-size: 0.95rem;
  color: white;
  transition: background 0.2s, padding 0.2s;
  background-color: #4e5359;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}

.is-active {
  background: #42464b !important;
}

.header {
  margin-top: 2rem;
}
</style>
